<template>
  <div>
    <span v-show="this.choose_id === '1'">
    <a-form v-show="this.userinfo_show === true" class="infogroup">
      <h1>个人信息</h1>
<!--                      {{userinfo}}-->
      <a-form-item class="infoitem" label="用户名">
        <div>
          {{ userinfo.login_name }}
          <!--          <span v-if="is_check_email!== -1">-->
          <!--        {{ userinfo.is_student }}-->
          <!--        {{ userinfo.is_teacher }}-->
          <!--        </span>-->

          <a-tag v-if="is_check_email === '-1'">
                     <span v-if="language ==='cn'">
                   {{ dictionary.cn.User }}
                  </span>
            <span v-if="language ==='en'">
                     {{ dictionary.en.User }}
                  </span>
            <span v-if="language ==='fr'">
                     {{ dictionary.fr.User }}
                  </span>
          </a-tag>
          <a-tag v-if="is_check_email ==='1'">
                     <span v-if="language ==='cn'">
                   {{ dictionary.cn.Membre }}
                  </span>
            <span v-if="language ==='en'">
                     {{ dictionary.en.Membre }}
                  </span>
            <span v-if="language ==='fr'">
                     {{ dictionary.fr.Membre }}
                  </span>
          </a-tag>
          <a-tag v-if="userinfo.vip_status ===1" color="#f50">
            VIP
          </a-tag>
        </div>
        <div v-if="userinfo">
          <a-tag v-if="userinfo.vip_order_status === 3" color="green">
            账单已上传等待审核
          </a-tag>

          <a-tag v-if="userinfo.vip_order_status === -1" color="orange">
            会员费待支付
          </a-tag>

          <a-button v-if="userinfo.vip_order_status === -2 && userinfo.vip_status!==1" html-type="submit"
                    style="margin-right: 20px" type="primary"
                    @click="getmembet">
            成为会员
          </a-button>
          <a-button v-if="userinfo.vip_order_status=== -1" html-type="submit" style="margin-right: 20px" type="primary"
                    @click="gourl(3,'/myinfo?my_id=3&choose=1')">
            支付
          </a-button>
          <!--          <a-button v-if="userinfo.vip_order_status=== -1" html-type="submit" style="margin-right: 20px" type="primary"-->
          <!--                    @click="paymembet">-->
          <!--            续费-->
          <!--          </a-button>-->

        </div>
      </a-form-item>
      <a-form-item class="infoitem" label="性别" v-if="userinfo.sex">
        <a-radio-group :default-value="userinfo.sex" button-style="solid" @change="Change_sex">
          <a-radio-button value="1">
            男
          </a-radio-button>
          <a-radio-button value="2">
            女
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item class="infoitem" label="名字">
        <a-input v-model="userinfo.name" class="infoinput"/>
      </a-form-item>
      <a-form-item class="infoitem" label="联系方式">
        <a-input v-model="userinfo.mobile" class="infoinput"/>
      </a-form-item>

      <a-form-item v-if="userinfo.more_info" class="infoitem" label="证件类型">
        <a-radio-group :defaultValue="userinfo.more_info.Certificate_Type" button-style="solid"
                       @change="Change_Certificate">
          <a-radio-button value="1">
            身份证
          </a-radio-button>
          <a-radio-button value="2">
            军官证
          </a-radio-button>
          <a-radio-button value="3">
            护照
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="userinfo.more_info" class="infoitem" label="证件号">
        <a-input v-model="userinfo.more_info.Certificate_Num" class="infoinput"/>
      </a-form-item>
      <a-form-item v-if="userinfo.more_info" class="infoitem" label="工作单位">
        <a-input v-model="userinfo.more_info.position" class="infoinput"/>
      </a-form-item>
      <a-form-item v-if="userinfo.more_info" class="infoitem" label="职务">
        <a-input v-model="userinfo.more_info.employer" class="infoinput"/>
      </a-form-item>
      <a-form-item v-if="userinfo.more_info" class="infoitem" label="邮寄地址">
        <a-input v-model="userinfo.more_info.address" class="infoinput"/>
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 24, offset:0 }" class="infoitem">
        <a-button html-type="submit" style="margin-right: 20px" type="primary" @click="saveall">
          保 存
        </a-button>
        <a-button class="btn-red" type="danger" @click="dologout">
          登 出
        </a-button>
      </a-form-item>

    </a-form>
    </span>
    <!--    <div v-show="this.choose_id === '2'" class="infogroup">-->
    <!--      <h1>我的账户</h1>-->
    <!--      <a-descriptions bordered class="mydescriptions">-->
    <!--        <a-descriptions-item label="网站会员">-->
    <!--          <a-badge status="processing" text="审核中" />-->
    <!--        </a-descriptions-item>-->
    <!--        <a-descriptions-item label="Billing Mode">-->
    <!--          <a-badge status="processing" text="审核中"  :span="2"/>-->
    <!--        </a-descriptions-item>-->
    <!--        <a-descriptions-item label="状态">-->
    <!--          <a-badge status="success" text="正常" />-->
    <!--        </a-descriptions-item>-->
    <!--        <a-descriptions-item label="我的证书" :span="3">-->
    <!--          <a-badge status="processing" text="Running" />-->
    <!--        </a-descriptions-item>-->
    <!--        <a-descriptions-item label="Status" :span="3">-->
    <!--          <a-badge status="processing" text="Running" />-->
    <!--        </a-descriptions-item>-->
    <!--        <a-descriptions-item label="Status" :span="3">-->
    <!--          <a-badge status="processing" text="Running" />-->
    <!--        </a-descriptions-item>-->
    <!--      </a-descriptions>-->
    <!--    </div>-->
    <div v-show="this.choose_id === '7'" class="infogroup">
      <div style="width:100%;padding-top:10px">
        <div class="titles">
          <h3>论文证明 </h3>
          <a-button style="margin-right:10px;margin-bottom:10px" type="primary" @click="upprove(1)">提交资料</a-button>
        </div>
        <a-table :columns="provecolumns" :data-source="prove_1" class="infolist" rowKey="id" :loading="loading">
          <template slot="updated_at" slot-scope="text" style="text-align: center">
            {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template slot="operation" slot-scope="text" style="text-align: center">
            <a-button html-type="submit" style="margin-right: 20px" type="primary" @click="delprove(text.id)">
              剔 除
            </a-button>

          </template>
        </a-table>
      </div>
      <div style="width:100%;padding-top:10px">
        <div class="titles">
          <h3>临床证明 </h3>
          <a-button style="margin-right:10px;margin-bottom:10px" type="primary" @click="upprove(2)">提交资料</a-button>
        </div>
        <a-table :columns="provecolumns" :data-source="prove_2" class="infolist" rowKey="id" :loading="loading">
          <template slot="updated_at" slot-scope="text" style="text-align: center">
            {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template slot="operation" slot-scope="text" style="text-align: center">
            <a-button html-type="submit" style="margin-right: 20px" type="primary" @click="delprove(text.id)">
              剔 除
            </a-button>

          </template>
        </a-table>

      </div>
      <div style="width:100%;padding-top:10px">
        <div class="titles">
          <h3>授课证明 </h3>
          <a-button style="margin-right:10px;margin-bottom:10px" type="primary" @click="upprove(3)">提交资料</a-button>
        </div>
        <a-table :columns="provecolumns" :data-source="prove_3" class="infolist" rowKey="id"  :loading="loading">
          <template slot="updated_at" slot-scope="text" style="text-align: center">
            {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template slot="operation" slot-scope="text" style="text-align: center">
            <a-button html-type="submit" style="margin-right: 20px" type="primary" @click="delprove(text.id)">
              剔 除
            </a-button>

          </template>
        </a-table>
      </div>
    </div>
    <div v-show="this.choose_id === '3'" class="infogroup">
      <a-button :loading="loading" style="margin-left: 20px" type="primary" @click="reforder_list">
        刷新
      </a-button>
      <!--            {{getItemsdata}}-->
      <a-table :columns="columns" :data-source="getItemsdata" class="infolist" rowKey="id">
        <template slot="pay_type" slot-scope="text" style="text-align: center">
          <a-tag v-if="text === 1" :color="'green'"><span>购买网站vip</span></a-tag>
          <a-tag v-if="text === 2" :color="'blue'">购买文章</a-tag>
          <a-tag v-if="text === 3" :color="'cyan'">考试</a-tag>
          <a-tag v-if="text === 4" :color="'cyan'">参加活动/课程</a-tag>
        </template>
        <template slot="created_at" slot-scope="text" style="text-align: center">
          {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="updated_at" slot-scope="text" style="text-align: center">
          {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="status" slot-scope="text, index" style="text-align: center">
          <a-tag v-if="index.status === 1" :color="'green'"><span>已通过</span></a-tag>
          <a-tag v-if="index.status === 2" :color="'red'"><span>失败</span></a-tag>
          <a-tag v-if="index.status === 3" :color="'green'"><span>已经上传资料</span></a-tag>
          <a-tag v-if="index.status === -1" :color="'red'">待支付</a-tag>
        </template>
        <template slot="operation" slot-scope="text" style="text-align: center">
          <a-button v-if="text.status === -1" html-type="submit" style="margin-right: 20px"
                    type="primary"
                    @click="paymembet(text.id)"
          >
            支 付
          </a-button>
          <a-button v-if="text.status === 3" html-type="submit" style="margin-right: 20px" type="primary">
            查 看
          </a-button>

        </template>
      </a-table>
    </div>
    <div v-show="this.choose_id === '4'" class="infogroup">
      <a-table :columns="Coursecolumns" :data-source="getCoursedata" class="infolist" rowKey="id">
        <template slot="created_at" slot-scope="text" style="text-align: center">
          {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="updated_at" slot-scope="text" style="text-align: center">
          {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="status" slot-scope="text, index" style="text-align: center">
          <a-tag v-if="index.status === 10" :color="'green'"><span>已结业/已发证</span></a-tag>
          <a-tag v-if="index.status === 2" :color="'red'"><span>失败</span></a-tag>
          <a-tag v-if="index.status === 3" :color="'green'"><span>已经上传资料</span></a-tag>
          <a-tag v-if="index.status === -1" :color="'red'">待审核</a-tag>
        </template>
        <template slot="operation" slot-scope="text" style="text-align: center">
          <a-button v-if="text.status !== 1" html-type="submit" style="margin-right: 20px"
                    type="primary"
                    @click="paymembet(text.id)"
          >
            提交支付信息
          </a-button>
          <a-button html-type="submit" style="margin-right: 20px" type="primary"
          >
            查看详情
          </a-button>

        </template>
      </a-table>
    </div>
    <div v-show="this.choose_id === '5'" class="infogroup">

      <!--      <div v-if="certificatedata.length === 0 && certificateteachdata.length === 0" style="width:100%; border:1px solid #cccccc">-->
      <!--      <a-empty/>-->
      <!--      </div>-->
      <div v-if="certificatedata.length > 0" style="width:100%;padding-top:10px;">
        <h3>讲师证书</h3>
        <a-empty v-if="certificatedata" class="cardlist"/>
        <a-card v-for="item in certificatedata" :key="item.id" class="certificateinfo" hoverable>
          <img
              slot="cover"
              :alt="item.course_title"
              src="https://www.psychomotricite.org/pdf/certificate.png"
          />
          <a-card-meta :title="item.course_title">
            <template slot="description">
              证书编号：{{ item.certificate_code }}<br/>
              颁发日期：{{ item.created_at }}
            </template>
          </a-card-meta>
        </a-card>

      </div>
      <div style="width:100%;padding-top:10px">
        <h3>学员证书</h3>
        <a-empty v-if="certificateteachdata" class="cardlist"/>
        <div class="certificatelist">
          <a-card v-for="item in certificateteachdata" :key="item.id" class="certificateinfo" hoverable
                  @click="goCertificate(item.id)">
            <img
                slot="cover"
                :alt="item.course_title"
                src="https://www.psychomotricite.org/pdf/certificate.png"
            />
            <a-card-meta :title="item.course_title">
              <template slot="description">
                证书编号：{{ item.certificate_code }}<br/>
                颁发日期：{{ item.created_at }}
              </template>
            </a-card-meta>
          </a-card>
        </div>
      </div>
    </div>
    <div v-show="this.choose_id === '6'" class="infogroup">

    </div>
    <a-modal v-model="tipsinfo" :footer="null" width="55%">
      <h1 class="toptitle">用户服务协议</h1>
      <div class="tips">
        <articletextonly :article_id="291"/>
      </div>
      <div>
        <!--        @click="handle"-->
        <a-button :disabled="isShow" style="margin-top:10px" type="primary" @click="joinMembers">{{ buttext }}
        </a-button>
      </div>
    </a-modal>
    <a-modal v-model="payinfo" :footer="null" width="55%">
      <div>
        <h2>请按照以下提示支付相关费用，并截图上传至后台，我们将在7天内处理并联系您</h2>
        <div class="pays">
          <articletextonly :article_id="293"/>
        </div>
      </div>
      <div class="piao">
        <img v-if="uploadImgUrl" :src="uploadImgUrl" alt="imgs"/>
      </div>
      <a-upload
          :action="server_url+'api/customer/tools/saveFile?uid='+uid+'&token='+token+'&path=/images/webup'"
          :multiple="false"
          :show-upload-list="true"
          name="file"
          @change="handleChange"
      >
        <div v-if="fileList.length <= 0">
          <a-button type="primary">上传文件</a-button>
        </div>
      </a-upload>
      <a-button style="margin-top:10px" type="primary" @click="payvip">提交资料</a-button>
    </a-modal>
    <a-modal v-model="proveinfo" :footer="null" width="35%" centered>
      <a-form class="infogroup">
      <a-form-item class="proveitem" label="文件标题">
        <a-input v-model="prove_title" class="infoinput"/>
      </a-form-item>
        <a-form-item class="proveitem" label="附件">
          <a-upload
              :action="server_url+'api/customer/tools/saveFile?uid='+uid+'&token='+token+'&path=/prove'"
              :multiple="false"
              :show-upload-list="true"
              name="file"
              @change="handleproveChange"
          >
            <div v-if="fileList.length <= 0">
              <a-button type="primary">上传文件</a-button>
            </div>
          </a-upload>
        </a-form-item>

      </a-form>
      <div style="text-align: right">
        <a-button style="margin-top:10px" type="primary" @click="saveprove">提交资料</a-button>
      </div>

    </a-modal>
    <!--    <a-modal v-model="info_" :footer="null" width="55%">-->
    <!--      <img v-if="uploadImgUrl" :src="uploadImgUrl" alt="imgs"/>-->
    <!--    </a-modal>-->
  </div>
</template>

<script>

import Config from "@/config";
import Storage from "@/common/storage";
import articletextonly from "@/views/articletextonly";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: "5%",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "订单id",
    dataIndex: "pay_code",
    scopedSlots: {customRender: "pay_code"}
  },
  {
    title: "金额",
    dataIndex: "money",
    scopedSlots: {customRender: "money"}
  },

  {
    title: "类型",
    dataIndex: "pay_type",
    scopedSlots: {customRender: "pay_type"}
  },
  {
    title: "订单创建时间",
    dataIndex: "created_at",
    scopedSlots: {customRender: "created_at"}
  },
  // {
  //   title: "订单审核通过时间",
  //   dataIndex: "updated_at",
  //   scopedSlots: {customRender: "updated_at"}
  // },

  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: {customRender: "status"}
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];
const Coursecolumns = [
  {
    title: "id",
    dataIndex: "id",
    width: "5%",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "课程名字",
    dataIndex: "course_title",
    scopedSlots: {customRender: "course_title"}
  },

  {
    title: "开课时间日期",
    dataIndex: "created_at",
    scopedSlots: {customRender: "created_at"}
  },
  {
    title: "最后更新日期",
    dataIndex: "updated_at",
    scopedSlots: {customRender: "updated_at"}
  },

  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: {customRender: "status"}
  },
  // {
  //   title: '操作',
  //   key: 'operation',
  //   scopedSlots: {customRender: 'operation'}
  // },
];
const provecolumns = [
  {
    title: "id",
    dataIndex: "id",
    width: "5%",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "文件类型",
    dataIndex: "file_type",
    scopedSlots: {customRender: "file_type"}
  },
  {
    title: "文件名字",
    dataIndex: "title",
    scopedSlots: {customRender: "title"}
  },
  {
    title: "最后更新日期",
    dataIndex: "updated_at",
    scopedSlots: {customRender: "updated_at"}
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

export default {
  components: {articletextonly},
  props: ['choose_id'],
  data() {
    return {
      language: localStorage.language,
      columns,
      Coursecolumns,
      provecolumns,
      userinfo: {},
      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
      is_check_email: Storage.is_check_email,
      data: [],
      fileList: [],
      // seconds: 864000, // 10天的秒数
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'

      count: 0, //倒计时 计数器
      buttext: "我已阅读并同意(10秒)",
      bank_bills: "",
      pay_id: "",
      uploadImgUrl: "",
      getItemsdata: [],
      getCoursedata: [],
      certificatedata: [],
      certificateteachdata: [],
      prove_1: [],
      prove_2: [],
      prove_3: [],
      prove_title: "",
      prove_type: "",
      prove_file_type: "",
      uploadproveUrl: "",

      loading: false,
      proveinfo: false,
      userinfo_show: false,
      payinfo: false,
      tipsinfo: false,
      dictionary: Config.data.dictionary,
      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 20,
      },
    }
  },
  created() {
    console.log("rightchoose_id", this.choose_id)
    if (this.choose_id === '1') {

      this.getuserinfo()
    }
    if (this.choose_id === '3') {
      this.getItemsByCustomer(1, this.pagination.pageSize)
    }
    if (this.choose_id === '4') {
      this.getMyCourse(1, this.pagination.pageSize)

    }
    if (this.choose_id === '5') {
      this.getcertificate(1, this.pagination.pageSize, 1)
      this.getcertificate(1, this.pagination.pageSize, 2)
      // this.getMycertificate(1, this.pagination.pageSize,1)
      // this.getMycertificate(1, this.pagination.pageSize,2)
      // this.getMycertificate(1, this.pagination.pageSize,3)
      // this.getMycertificate(1, this.pagination.pageSize,4)
    }
    if (this.choose_id === '7') {
      this.getmycertificate(1, this.pagination.pageSize, 1)
      this.getmycertificate(1, this.pagination.pageSize, 2)
      this.getmycertificate(1, this.pagination.pageSize, 3)
      // this.getmycertificate(1, this.pagination.pageSize, 4)
      // this.getMycertificate(1, this.pagination.pageSize,1)
      // this.getMycertificate(1, this.pagination.pageSize,2)
      // this.getMycertificate(1, this.pagination.pageSize,3)
      // this.getMycertificate(1, this.pagination.pageSize,4)
    }
  },
  methods: {
    reforder_list() {
      this.loading = true;
      this.getItemsdata = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.getItemsByCustomer(1, this.pagination.pageSize);
    },
    goCertificate(Certificateno) {

      this.$router.push({
        path: '/Certificateinfo',
        query: {
          Certificateno: Certificateno,
          t: new Date().getTime()
        }
      });
    },
    getCount() {

      this.isShow = true;//倒计时
      this.count = 10; //赋值3秒
      var times = setInterval(() => {
        console.log("-1秒", this.count)
        this.buttext = '我已阅读并同意(' + this.count + '秒)'
        this.count--; //递减
        if (this.count <= 0) {
          // <=0 变成获取按钮
          this.buttext = '我已阅读并同意'
          this.isShow = false;

          clearInterval(times);
        }
      }, 1000); //1000毫秒后执行
    },

    countdowm(timestamp) {
      let self = this;
      let timer = setInterval(function () {
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if (t > 0) {
          let day = Math.floor(t / 86400000);
          let hour = Math.floor((t / 3600000) % 24);
          let min = Math.floor((t / 60000) % 60);
          let sec = Math.floor((t / 1000) % 60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = '';
          if (day > 0) {
            format = `${day}天${hour}小时${min}分${sec}秒`;
          }
          if (day <= 0 && hour > 0) {
            format = `${hour}小时${min}分${sec}秒`;
          }
          if (day <= 0 && hour <= 0) {
            format = `${min}分${sec}秒`;
          }
          self.content = format;
        } else {
          clearInterval(timer);
          self.content = self.endText;
        }
      }, 1000);
    },
    initRead() {
      let timer = null
      if (this.readBtn.time) {
        this.readBtn.text = `我已阅(${this.readBtn.time}秒)`
        this.readBtn.time--
        timer = setTimeout(() => {
          this.initRead()
        }, 1000)
      } else {
        this.readBtn.disabled = false
        this.readBtn.text = '我已阅'
        clearTimeout(timer)
      }
    },
    getmembet() {

      this.tipsinfo = true;
      this.getCount() //调用定时器
      // this.initRead();
    },
    paymembet(e) {
      console.log("e", e)
      this.pay_id = e
      this.tipsinfo = false;
      this.payinfo = true;
      this.fileList = [];
      // this.initRead();
    },
    upprove(e) {
      console.log("prove_type", e)
      this.prove_type = e
      this.prove_title= ""
      this.prove_file_type= ""
      this.uploadproveUrl=""
      this.proveinfo = true;
    },

    gourl(id, jump_url) {
      this.$router.push({
        path: jump_url,
        query: {
          id: id,
          t: Date.now(),
        }
      });
    },
    Change_sex(value) {
      console.log("sex", value.target.value)
      this.userinfo.sex = value.target.value
    },
    Change_Certificate(value) {
      console.log("Certificate_Type", value.target.value)
      this.userinfo.more_info.Certificate_Type = value.target.value
    },
    handleChange(info) {
      const status = info.file.status;
      console.log("status", status)
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);

      }
      if (status === 'removed') {
        console.log("removed")
        console.log(info.file, info.fileList);
        this.fileList = [];
        this.uploadImgUrl = "";

      }
      if (status === 'done') {
        this.fileList = info.fileList;
        this.$message.success(`file uploaded successfully.`);
        this.uploadImgUrl = this.server_url + info.file.response.src
      } else if (status === 'error') {
        this.$message.error(` file upload failed.`);
      }
    },
    handleproveChange(info) {
      const status = info.file.status;
      console.log("status", status)
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);

      }
      if (status === 'removed') {
        console.log("removed")
        console.log(info.file, info.fileList);
        this.fileList = [];
        this.uploadproveUrl = "";

      }
      if (status === 'done') {
        this.fileList = info.fileList;
        this.$message.success(`file uploaded successfully.`);
        this.prove_title = info.file.name
        this.prove_file_type=info.file.name.substring(info.file.name.lastIndexOf('.')+1)
        this.uploadproveUrl = this.server_url + info.file.response.src
      } else if (status === 'error') {
        this.$message.error(` file upload failed.`);
      }
    },

    async saveall() {
      console.log("getuserinfo", this.language)
      this.loading = true;

      let now = Date.now();
      let result = await this.$post("/api/customer/updateSelfItem", {

        // token: Storage.token,
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          name: this.userinfo.name,
          mobile: this.userinfo.mobile,
          sex: this.userinfo.sex,
          email: this.userinfo.login_name,
          photo: this.userinfo.photo,
          more_info: this.userinfo.more_info,
        },
        t: now,
      });
      if (result.status === true) {
        this.$message.success('用户信息已经保存');
        Storage.name = this.userinfo.name;
        this.getuserinfo()
        return;
      }

      this.loading = false;
    },
    async saveprove() {
      console.log("getuserinfo", this.language)
      this.loading = true;

      let now = Date.now();
      let result = await this.$post("/api/customer/moreFile/created", {

        // token: Storage.token,
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          type: this.prove_type,
          file_type: this.prove_file_type,
          title: this.prove_title,
          file_url: this.uploadproveUrl,
        },
        t: now,

      });

      if (result.status === true) {
        this.$message.success('证明文件已提交');
        this.proveinfo = false;
        this.getmycertificate(1, this.pagination.pageSize, 1)
        this.getmycertificate(1, this.pagination.pageSize, 2)
        this.getmycertificate(1, this.pagination.pageSize, 3)
        return;
      }


      this.loading = false;
    },
    async joinMembers() {
      this.tipsinfo = false;
      let result = await this.$post("/api/customer/order/buyStudent", {
        // token: Storage.token,
        uid: Storage.uid,
        token: Storage.token,
      });

      if (result.status === true) {
        this.$message.success('请按照支付说明进行支付');
        // this.payinfo = true;
        this.getuserinfo()
        return;
      }

      this.loading = false;
    },
    async payvip() {
      this.payinfo = false;
      let result = await this.$post("/api/customer/order/updateBill", {

        // token: Storage.token,
        uid: Storage.uid,
        token: Storage.token,
        id: this.pay_id,
        save_value: {
          bank_bills: this.uploadImgUrl,
        },


      });
      if (result.status === true) {

        this.getItemsByCustomer(1, this.pagination.pageSize)
        this.loading = false;
      }

      this.loading = false;
    },
    async getuserinfo() {
      console.log("getuserinfo", this.language)
      this.loading = true;

      let now = Date.now();
      let result = await this.$get("/api/customer/getCustomerInfo", {
        params: {
          // token: Storage.token,
          uid: Storage.uid,
          token: Storage.token,
          t: now,
        }
      });

      if (result.status === true) {
        this.userinfo = result.data;

        if (result.data.sex === null) {
          this.userinfo.sex = ""
        } else {
          this.userinfo.sex = result.data.sex.toString()
        }
        if (this.userinfo.more_info === undefined) {
          console.log("more_infoundefined", this.userinfo)
        }
        if (this.userinfo.more_info === null) {
          console.log("没有more_info", this.userinfo)
          this.userinfo.more_info = {
            Certificate_Type: "",
            Certificate_Num: "",
            address: "",
            position: "",
            employer: "",
          }
        }
        console.log("用户信息", this.userinfo)
        console.log("用户信息result", result.data)
        this.userinfo_show = true;
        this.loading = false;
      }

      this.loading = false;
    },
    async getItemsByCustomer(pages, limit) {
      console.log("获取订单")
      this.loading = true;

      let now = Date.now();
      let result = await this.$get("/api/customer/order/getItemsByCustomer", {
        params: {
          // token: Storage.token,
          uid: Storage.uid,
          token: Storage.token,
          pages: pages,
          limit: limit,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("Customer", result.data.list)
        this.getItemsdata = result.data.list
        this.loading = false;
      }

      this.loading = false;
    },
    async getMyCourse(pages, limit) {
      console.log("获取课程")
      this.loading = true;

      let now = Date.now();
      let result = await this.$get("/api/customer/certificate/getMyCourse", {
        params: {
          // token: Storage.token,
          uid: Storage.uid,
          token: Storage.token,
          pages: pages,
          limit: limit,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("getCoursedata", result.data.list)
        this.getCoursedata = result.data.list
        this.loading = false;
      }

      this.loading = false;
    },
    async getMycertificate(pages, limit, type) {
      console.log("获取课程")
      this.loading = true;

      let now = Date.now();
      let result = await this.$get("/api/customer/moreFile/list", {
        params: {
          // token: Storage.token,
          uid: Storage.uid,
          token: Storage.token,
          pages: pages,
          limit: limit,
          type: type,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("getCoursedata", result.data.list)
        if (type === 1) {
          this.prove_1 = result.data.list
        }
        if (type === 2) {
          this.prove_2 = result.data.list
        }
        if (type === 3) {
          this.prove_3 = result.data.list
        }

        this.loading = false;
      }

      this.loading = false;
    },
    async getcertificate(pages, limit, type) {
      console.log("获取证书列表")
      this.certificatedata = [];
      let now = Date.now();
      let result = await this.$get("/api/customer/certificate/selfList", {
        params: {
          // token: Storage.token,
          uid: Storage.uid,
          token: Storage.token,
          pages: pages,
          limit: limit,
          type: type,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("Customer", result.data.list)
        if (type === 1) {
          this.certificatedata = result.data.list
        }
        if (type === 2) {
          this.certificateteachdata = result.data.list
        }

        this.loading = false;
      }

      this.loading = false;
    },
    async getmycertificate(pages, limit, type) {
      console.log("获取证书列表")
      this.certificatedata = [];
      let now = Date.now();
      let result = await this.$get("/api/customer/moreFile/list", {
        params: {
          // token: Storage.token,
          uid: Storage.uid,
          token: Storage.token,
          pages: pages,
          limit: limit,
          type: type,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("prove", result.data.list)
        if (type === 1) {
          this.prove_1 = result.data.list
        }
        if (type === 2) {
          this.prove_2 = result.data.list
        }
        if (type === 3) {
          this.prove_3 = result.data.list
        }

        this.loading = false;
      }

      this.loading = false;
    },
    async dologout() {
      Storage.uid = "";
      Storage.token = "";
      Storage.login_name = "";
      Storage.is_check_email = "";
      Storage.token_timeout = "";
      Storage.account = "";
      Storage.name = "";
      Storage.sex = "";
      Storage.mobile = "";
      this.$router.push({path: "/"});
    },
    async delprove(id) {
      this.loading = true;
      let now = Date.now();
      let result = await this.$post("/api/customer/moreFile/deleted", {
          // token: Storage.token,
          uid: Storage.uid,
          token: Storage.token,
          id: id,
          t: now,
      });

      if (result.status === true) {
        this.$message.success('已剔除');
        this.getmycertificate(1, this.pagination.pageSize, 1)
        this.getmycertificate(1, this.pagination.pageSize, 2)
        this.getmycertificate(1, this.pagination.pageSize, 3)

        this.loading = false;
      }

      this.loading = false;
    },
  },
}

</script>

<style lang="scss" scoped>

.infogroup {
  h1 {
    width: 100%;
  }

  .mydescriptions {
    width: 100%;
  }

  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  flex-wrap: wrap;

}

.infoitem {
  width: 70%;
  padding-right: 20px;
  margin-bottom: 0px !important;
}
.proveitem {
  width: 100%;
  padding-right: 20px;
  margin-bottom: 0px !important;
}

.infolist {
  width: 100%;
  min-height: 250px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom:20px;
}

.infoinput {
  border-radius: 15px;
  height: 30px;
}

/deep/ .ant-select-selection {
  border-radius: 15px !important;
}

.tips {
  line-height: 180%;
  height: 650px;
  overflow-y: scroll;
  padding: 15px;
  border: 1px solid #E6EAEB;

  h1 {
  }

  p {
    text-indent: 2em;
  }

  li {
    list-style: none
  }
}

.pays {
  line-height: 180%;
  height: 350px;
  overflow-y: scroll;
  padding: 15px;
  border: 1px solid #E6EAEB;

  h1 {
  }

  p {
    text-indent: 2em;
  }

  li {
    list-style: none
  }
}

.toptitle {
  text-align: center
}

.piao {
  min-height: 150px;
  max-height: 300px;
  padding: 10px;
  background-color: #E6EAEB;
  margin-bottom: 10px;
  margin-top: 10px;

  img {
    width: auto;
    height: 200px;
  }
}

.certificatelist {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  /deep/ .ant-card-cover {
    padding: 10px;
  }

  .certificateinfo {
    width: 30%;
    margin-right: 2%;
    margin-bottom: 15px;
  }
}

.cardlist {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  border-radius: 10px;
}
.titles{
  display: flex;
  justify-content:space-between;
}
</style>