 <template>
   <div>
     <a-list :data-source="left_child" bordered class="left">
       <a-list-item slot="renderItem" slot-scope="item, index" :class="item.id === choose_id? 'active' : ''"
                    class="title" @click='gourl(item.id, item.jump)' :key="index">
         {{ item.name.cn }}
       </a-list-item>
     </a-list>
   </div>
</template>

<script>


export default {
  props: ['choose_id'],
  data() {
    return {
      left_child:[
        {
          name:{
            cn:"个人信息_cn",
            en:"个人信息_en",
            fr:"个人信息_fr",
          },
          jump:"/myinfo?my_id=1&choose=1",
          id:"1"
        },
        {
          name:{
            cn:"证明材料_cn",
            en:"证明材料_en",
            fr:"证明材料_fr",
          },
          jump:"/myinfo?my_id=7&choose=7",
          id:"7"
        },
        // {
        //   name:{
        //     cn:"我的账户_cn",
        //     en:"我的账户_en",
        //     fr:"我的账户_fr",
        //   },
        //   jump:"/myinfo?my_id=2&choose=1",
        //   id:"2"
        // },
        {
          name:{
            cn:"我的订单_cn",
            en:"我的订单_en",
            fr:"我的订单_fr",
          },
          jump:"/myinfo?my_id=3&choose=3",
          id:"3"
        },{
          name:{
            cn:"我的课程_cn",
            en:"我的课程_en",
            fr:"我的课程_fr",
          },
          jump:"/myinfo?my_id=4&choose=4",
          id:"4"
        },{
          name:{
            cn:"我的证书_cn",
            en:"我的证书_en",
            fr:"我的证书_fr",
          },
          jump:"/myinfo?my_id=5&choose=5",
          id:"5"
        },{
          name:{
            cn:"站内信_cn",
            en:"站内信_en",
            fr:"站内信_fr",
          },
          jump:"/myinfo?my_id=6&choose=6",
          id:"6"
        },

      ]
    }
  },
  created() {
    console.log("choose_id",this.choose_id)
  },
  methods: {
    gourl(id,jump_url) {
      this.$router.push({
        path: jump_url,
        query: {
          id: id,
          t:Date.now(),
        }
      });
    },
    },
}

</script>

<style lang="scss" scoped>

.active {
  color: #fff;
  background: #CE1126;
}
.left_container {

  .left {
    width: 170px !important;
    padding: 9px 10px;
    border-radius: 8px;
    margin-right: 38px;
    color: #fff;
    background-color: #002654;
    cursor: pointer;

    /deep/ .ant-list-item {
      border-bottom: 0px solid #e8e8e8 !important;
    }
  }
}

</style>