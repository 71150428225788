<template>

  <div>
    <topslogan/>
    <div class="top_head">
      <topnav :category_id="$route.query.choose" :mask=false></topnav>
    </div>
    <div class="category_list">
      <div class="left_container">
        <myinfoleft :choose_id="$route.query.my_id"/>
      </div>
      <div class="right_container">
        <myinfoRight :choose_id="$route.query.my_id"/>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>

import Config from "@/config";
import topnav from "@/views/topnav";
import topslogan from "@/views/topslogan";
import myinfoleft from "@/views/myinfoleft";
import myinfoRight from "@/views/myinfoRight";
import bottom from "@/views/bottom";

export default {
  components: {topslogan, topnav, myinfoleft,myinfoRight, bottom},
  name: "category_list",
  props: ['my_id', 'choose'],
  data() {
    return {
      articles_category_id: "",
      articles_category_pid: "",
      server_url: Config.data.server_url,
      categoryinfoData: "",
      categoryinfopidData: "",
      breadcrumb_name: "",
      show_id: "",
      loading: false,
      language: localStorage.language,
      categoryData: [],
      articlesData: [],
      current: 1,

      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 10,
      },
    }
  },
  created() {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.top_head {
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  height: 112px;
  background-color: #002654;

}

.breadcrumb_bg {
  background: #F2F4F5;
  padding-top: 18px;
  height: 64px;
}

.breadcrumb {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;

}

.title {
  color: #fff;
}

.active {
  color: #fff;
  background: #CE1126;
}

.category_list {
  height: 100%;

  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  display: flex;

  justify-content: flex-start;
  padding-top: 80px;
  padding-bottom: 80px;

  .right_container {
    width: 100%;
    background-color: #fff;
    height: 100%;
  }

  .left_container {

    .left {
      width: 170px !important;
      padding: 9px 10px;
      border-radius: 8px;
      margin-right: 38px;
      color: #fff;
      background-color: #002654;
      cursor: pointer;

      /deep/ .ant-list-item {
        border-bottom: 0px solid #e8e8e8 !important;
      }
    }
  }

  .left_info {
    padding-top: 32px;

    padding-left: 40px;
    padding-right: 40px;

    h4 {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 34px;
      margin-top: 0;
      padding-top: 0;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      color: #000000;
      line-height: 28px;
      margin-bottom: 20px;
      min-height: 100px;
      max-height: 100px;

    }
  }

  .btn-red {
    color: #fff;
    background-color: #CE1126 !important;
    border-color: #CE1126 !important;
  }

  .articles_list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 34px;
    background-color: #F2F4F5;
    border-radius: 8px;
  }

  .right_info {
    width: 266px;
  }

  .right_img {
    width: 266px;
    height: 280px;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
</style>